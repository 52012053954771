import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[fcScrollNearEnd]',
  standalone: true,
})
export class ScrollNearEndDirective implements OnInit, OnDestroy {
  @Input() threshold = 50;
  @Output() nearEnd: EventEmitter<void> = new EventEmitter<void>();
  private subscription = new Subscription();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.checkScrollEnd();
    this.subscription = fromEvent(this.el.nativeElement, 'scroll')
      .pipe(debounceTime(100))
      .subscribe(() => this.checkScrollEnd());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private checkScrollEnd(): void {
    scrollNearEndListener(
      this.el.nativeElement,
      () => this.nearEnd.emit(),
      this.threshold,
    );
  }
}

export const scrollNearEndListener = <T extends Element>(
  scrollItem: T,
  action: Function,
  threshold = 50,
) => {
  if (scrollItem.scrollHeight > scrollItem.clientHeight) {
    const scrollPosition =
      scrollItem.scrollTop + (scrollItem as any).offsetHeight;
    const maxScroll = scrollItem.scrollHeight;
    if (maxScroll - scrollPosition <= threshold) {
      action();
    }
  }
};
